import React, { useState, useEffect } from "react";
import CarouselAuto from "react-bootstrap/Carousel";
import "react-multi-carousel/lib/styles.css";
import "@detail/Pages/DetailPage/CarrouselStyle.css";

import Slide from "./slide/Slide";
import { MULTITENANT_CONSTANTS, TENANT } from "../../Resources/Multitenant/tenantConstants";
import style from "./style.module.css";
import "./carousel.css";
import defaultBanner from "@app/Resources/Images/default_banner.png";
import { SWITCHER_WIDTH } from "../../Utils/Constants";
import { CustomPrevIcon, CustomNextIcon } from "../../../../detail/src/Pages/DetailPage/Widgets/ArrowButtons";

const WidgetHighlight_v2 = (props) => {
  const { playlist, setRef, getRef, trigger, clickCallback, rotateTime } = props;
  const [renderMobileVersion, setRenderMobileVersion] = useState(false);

  const showIndicators = () => {
    return MULTITENANT_CONSTANTS[TENANT].showSlideHighlightIndicators;
  };
  const showControls = () => {
    return !renderMobileVersion || MULTITENANT_CONSTANTS[TENANT].showControlsHighlightedComponent;
  }
  const handleResize = () => {
    setRenderMobileVersion(window.innerWidth <= SWITCHER_WIDTH);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className={`${style.carouselContainer} ${style.principal}`}>
      {playlist?.length && (
        <CarouselAuto
          draggable
          id="hightligthCarousel_v2"
          controls={showControls()}
          interval={rotateTime}
          indicators={showIndicators()}
          prevIcon={<CustomPrevIcon className="custom-icon custom-prev-icon" />}
          nextIcon={<CustomNextIcon className="custom-icon custom-next-icon" />}
        >
          {playlist?.map((w) => (
            <CarouselAuto.Item key={w.id}>
              <Slide
                w={w}
                setRef={setRef}
                getRef={getRef}
                defaultBanner={defaultBanner}
                trigger={trigger}
                clickCallback={clickCallback}
                renderMobileVersion={renderMobileVersion}
              />
            </CarouselAuto.Item>
          ))}
        </CarouselAuto>
      )}
    </div>
  );
};

export default WidgetHighlight_v2;
