import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import HandleClickOutside from "../../../../../app/src/Components/DOM/Click/HandleClickOutside";
import "./DesactivateUserModalComponent.css";

const DeactivateUserModalComponent = ({
    refModal,
    handleDeactivateUserModal,
    handleDeactivateUser,
}) => {
    const { t } = useTranslation();
    const { username } = useSelector((state) => state.commonState.userData);

    return (
        <div className="ModalBackground">
            <HandleClickOutside
                elementref={refModal}
                callback={() => handleDeactivateUserModal(false)}
            />
            <div className="ModalContainer" ref={refModal}>
                <p className="ModalTitle">
                    {t("perfil.deactivate.confirm.title")} {username}

                </p>
                <div className="ButtonContainer">
                    <button
                        onClick={() => handleDeactivateUserModal(false)}
                        className="Button Button--cancel"
                    >
                        {t("global.button.cancel")}
                    </button>
                    <button
                        onClick={handleDeactivateUser}
                        className="Button Button--danger"
                    >
                        {t("perfil.deactivate.confirm.button")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeactivateUserModalComponent;
