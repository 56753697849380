import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";

import "./Footer.css";
import packageJson from "@app/../package.json";
import { useTranslation } from "react-i18next";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../Resources/Multitenant/tenantConstants";
import { URL_BASE_IMAGE } from "../../../services/servicesConstants";
import {getFileService} from "../../../services/services";
import {getToken} from "../../../Utils/utils";
import keycloak from "../../../Utils/keycloak";

function Footer() {
  const supportEmail = MULTITENANT_CONSTANTS[TENANT]?.supportEmail;
  const { interfaceUrls } = useSelector((state) => state.commonState);
  const { sponsors } = useSelector((state) => state.commonState);
  const { t } = useTranslation();

  const [imageUrls, setImageUrls] = useState({});

  const getFileCallback = (response, imageId) => {
        setImageUrls((prevUrls) => ({
            ...prevUrls,
            [imageId]: `${URL_BASE_IMAGE}${response.data.data.asset_url}`,
        }));
    };

    const getImageSponsor = (imageId) => {
        const userToken = getToken(keycloak);
        getFileService(MULTITENANT_CONSTANTS[TENANT].tenantServer, imageId, userToken, (response) => getFileCallback(response, imageId));
    };

    useEffect(() => {
        sponsors.forEach((sponsor) => {
            if (sponsor.logo) {
                getImageSponsor(sponsor.logo);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sponsors]);


  return (
    <div className="Footer">
      <div className="FooterBody">
        {sponsors &&
          sponsors.map((a, key) => (
               !!a?.logo && (
                <div key={`${a?.company}-${key}`} className="ImageDiv">
                <button
                  className="ImageButton"
                  onClick={(e) => {
                    e.preventDefault();
                    if (a.onclickurl) {
                      window.open(a.onclickurl, "_blank");
                    }
                  }}
                >
                  <img
                    className="ImageEurope"
                  /*https://streamer-cdn.ott.tiivii.com/v1/reme/ott_tiivii/files/2967*/
                  /*src={`${baseUrl}${URL_REM}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/assets/${a?.logo}`}*/
                    src={imageUrls[a?.logo]}
                    alt={a?.company}
                  />
                </button>
              </div>
            )
          ))}
        <span className="versionTag">{packageJson.version}</span>
      </div>
      {!!supportEmail && (
        <div className={"footerLink"}>
            <div>
                <a href={`mailto:${supportEmail}`} target={"_blank"} rel="noreferrer">
                        {t("suport.contact")} {supportEmail}
                    </a>
            </div>
        </div>
          )}

        <div className={"footerLink"}>
        {!!MULTITENANT_CONSTANTS[TENANT]?.urlFaqs && (
            <div>
                <a href={MULTITENANT_CONSTANTS[TENANT]?.urlFaqs} target={"_blank"} rel="noreferrer">
                        {t("suport.faqs")}
                </a>
            </div>
            )}
            {(interfaceUrls.termsAndConditionsUrl || !!MULTITENANT_CONSTANTS[TENANT]?.urlTermsAndConditions)  && (
          <div>
              <a href={interfaceUrls?.termsAndConditionsUrl ? interfaceUrls.termsAndConditionsUrl : MULTITENANT_CONSTANTS[TENANT]?.urlTermsAndConditions}
                 target={"_blank"} rel="noreferrer">
                  {t("legal.termsAndConditions")}
              </a>
          </div>
        )}
          {(interfaceUrls.privacy_url || !!MULTITENANT_CONSTANTS[TENANT]?.urlPrivacyStatement) && (
              <div>
                  <a href={interfaceUrls?.privacy_url ? interfaceUrls.privacy_url : MULTITENANT_CONSTANTS[TENANT]?.urlPrivacyStatement}
                     target={"_blank"} rel="noreferrer">
                      {t("legal.privacyStatement")}
                  </a>
              </div>
          )}
          <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" className="cky-banner-element">
                  {t("suport.cookies")}
              </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
