import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";

import {
  deleteUserInterestsService,
  getUserInterestsService,
  updateUserInterestsService,
} from "../../../../../app/src/services/services";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import { saveUserDataActionCreator } from "../../../../../app/src/actions/commonActions";
import { URL_BASE_IMAGE } from "../../../../../app/src/services/servicesConstants";
import { renderImageUrl } from "../../../../../app/src/Utils/utils";

const SelectCategoriesComponent = ({ userProfile }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userData } = useSelector((state) => state.commonState);
  const { keycloak } = useKeycloak();
  const userToken = keycloak?.token;

  const [categorias, setCategorias] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [categoriasUnselect, setCategoriasUnselect] = useState([]);
  const [advise, setAdvise] = useState(false);

  useEffect(() => {
    const interestsCallback = (response) => {
      const availableInterests = response.data.map((item) => {
        const image = item.image.data.thumbnails.find(
          (data) => data.key === "directus-medium-contain"
        );
        return {
          id: item.id,
          name: item.name,
          image: {
            id: item.image.id,
            width: image.width,
            height: image.height,
            url: renderImageUrl(image.relative_url),
          },
        };
      });
      setCategorias(availableInterests);
    };

    if (userData?.appname && userToken) {
      getUserInterestsService(userData.appname, userToken, interestsCallback);
    }
  }, [userData, userToken]);

  useEffect(() => {
    if (userProfile?.interests && categorias?.length > 0) {
      const selectedCategories = categorias.filter((categoria) =>
        userProfile.interests.includes(categoria.id)
      );
      setCategoriesSelected(selectedCategories);
    }
  }, [userProfile, categorias]);

  useEffect(() => {
    if (categorias?.length > 0) {
      const selectedCategoryIds = categoriesSelected.map((c) => c.id);
      const unselectedCategories = categorias.filter(
        (c) => !selectedCategoryIds.includes(c.id)
      );
      setCategoriasUnselect(unselectedCategories);
    }
  }, [categoriesSelected, categorias]);

  const updateUserInterestsCallback = (response) => {
    dispatch(saveUserDataActionCreator(response));
  };

  const extractIds = (data) => {
    return data
      .map((item) => {
        if (item && typeof item === "object" && "id" in item) {
          return item.id;
        }
        return undefined;
      })
      .filter((id) => id !== undefined);
  };

  const deleteInterest = (categorySelected) => {
    const interestsToDelete = extractIds([categorySelected]);
    deleteUserInterestsService(
      MULTITENANT_CONSTANTS[TENANT].tenantServer,
      userProfile.username,
      interestsToDelete,
      userToken,
      updateUserInterestsCallback
    );
  };

  const updateInterests = (categoriesUser) => {
    setCategoriesSelected(categoriesUser);
    const payload = {
      interests: extractIds(categoriesUser),
    };
    updateUserInterestsService(
      MULTITENANT_CONSTANTS[TENANT].tenantServer,
      userProfile.username,
      payload,
      userToken,
      updateUserInterestsCallback
    );
  };

  const updateCategories = (categorySelected, deleteCategory) => {
    setAdvise(false);
    let categoriesUser = !!categoriesSelected?.length
      ? [...categoriesSelected]
      : [];
    let index = categoriesUser.findIndex(
      (category) => category.id === categorySelected.id
    );

    if (deleteCategory && index !== -1) {
      categoriesUser.splice(index, 1);
    } else if (!deleteCategory && index === -1) {
      categoriesUser.push(categorySelected);
    }

    if (categoriesUser.length > 0) {
      setCategoriesSelected(categoriesUser);
      if (deleteCategory) {
        deleteInterest(categorySelected);
      } else {
        updateInterests(categoriesUser);
      }
    } else {
      setAdvise(true);
    }
  };

  return (
    <div className="Intereses-usuario">
      <h2 className={"Title-bold"}>{t("perfil.intereses")}</h2>
      <Row>
        <Col
          xs="12"
          className={"ProfileInterestsList ProfileSelectedInterestsList"}
        >
          {!!categoriesSelected &&
            categoriesSelected?.map((category) => (
              <div
                style={{ backgroundImage: `url(${URL_BASE_IMAGE + category?.image?.url})` }}
                className="Interes"
                onClick={() => updateCategories(category, true)}
                key={category.id}
              >
                {category.name}
              </div>
            ))}
        </Col>
        <Col xs="12" className={"ProfileInterestsList"}>
          {!!categoriasUnselect &&
            categoriasUnselect?.map((category) => (
              <div
                className="Interes Unselect"
                onClick={() => updateCategories(category, false)}
                key={category.id}
              >
                {category.name}
              </div>
            ))}
        </Col>
      </Row>
      {!!advise && (
        <div className={"textAlert"}>{t("register.interestAlone")}</div>
      )}
    </div>
  );
};

export default SelectCategoriesComponent;
