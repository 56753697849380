import React from "react";
import { Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import "./PerfilComponents/DesactivateUserModalComponent.css";

const CustomModal = ({ showModal, modalMessage }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={showModal} centered>
      <div className="ModalContainer">
        <h4 className="ModalTitle">{t("notification.info.title")}</h4>
        <p className="ModalMessage">{modalMessage}</p>
      </div>
    </Modal>
  );
};

export default CustomModal;
