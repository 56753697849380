export const CustomPrevIcon = () => (
    <svg width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
      <polyline points="15 18 9 12 15 6" />
    </svg>
  );
  
  export const CustomNextIcon = () => (
    <svg width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4">
      <polyline points="9 18 15 12 9 6" />
    </svg>
  );